html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: sans-serif;
  font-weight: 400;
}

::-moz-selection {
  color: #fff;
  background: rgba(68, 68, 68, 0.8);
}

::selection {
  color: #fff;
  background: rgba(68, 68, 68, 0.8);
}

.App {
  text-align: center;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #000;
  background: -webkit-linear-gradient(rgba(43, 14, 61, 0.7), rgba(19, 11, 42, 0.7)), url("../public/background.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.App-header {
  position: relative;
  z-index: 2;
  color: #fff;
}

.locale-select {
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 4;
  padding: 10px 20px;
  color: #007BFF;
  background-color: transparent;
  border: 1px solid #007BFF;
  border-radius: 5px;
  cursor: pointer;
}

.particles {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.particle {
  position: absolute;
  width: 0.35rem;
  height: 0.35rem;
  border-radius: 50%;
  background-color: white;
  transform-origin: center;
  -webkit-filter: blur(0.1rem);
  filter: blur(0.1rem);
  z-index: 0;
}

.profile-card {
  background: white;
  padding: 2rem 3rem;
  border-radius: 8px;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 640px;
  margin: 2rem;
  z-index: 1;
}

.profile-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #eee;
}

.profile-text h1 {
  font-family: EB Garamond, serif;
  font-size: 4rem;
  font-weight: 600;
  letter-spacing: -.015em;
  line-height: 1;
  margin-bottom: 10px;
}

.profile-text h1.japanese {
  font-size: 3rem;
}

.cta-button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
}

.close-button {
  float: right;
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
}

input, textarea {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  background-color: #007BFF;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.message-toast {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 4;
}

.message-toast.success {
  background-color: #4bb543;
}

.message-toast.error {
  background-color: #d9534f;
}

@media (max-width: 480px) {
  .profile-card {
    padding: 2rem;
  }

  .profile-info {
    flex-direction: column;
  }

  .profile-text h1 {
    margin-top: 0;
    font-size: 2rem;
  }

  .profile-text h1.japanese {
    font-size: 1.5rem;
  }
}